
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { showMessage } from '@/components/mixins/showMessage';
import { genericHelpers } from '@/components/mixins/genericHelpers';

import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';

import Table from '@/components/Table.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import Modal from '@/components/ModalAliare.vue';
import AliareInput from '@/components/AliareInput.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';
import FilterIcon from '@/components/FilterIcon.vue';
import FilterBar from '@/components/FilterBar.vue';


import { removeItemFromArrayByValue, filter, truncate } from '@/components/helpers';
import { previousRoute } from '../router/index.ts';
import WebStorage from '@/common/WebStorage';
import { equivalencePermission } from '@/components/mixins/permissions/equivalences';

export default mixins(
	nodeHelpers,
	equivalencePermission,
	showMessage,
	genericHelpers,
).extend({
	components: {
		BackgroundView,
		ModalButtonCancel,
		ModalButtonSuccess,
		Table,
		Modal,
		AliareInput,
		AlertNotificationModal,
		FilterIcon,
		FilterBar,
	},
	name: 'EquivalencesDetailing',
	data() {
		return {
			isSearching: false,
			searchingList: '',
			currentEquivalence: '',
			activeTab: 'registers',
			isLoading: false,
			dataTableValueEquivalence: [],
			dataTableOriginalEquivalence: [],
			dataTableDescriptionEquivalence: [],
			applyedFilters: [],
			showAlert: false,
			isLoadingAction: false,

			messageNameError: '',
			messageNameSuccess: '',

			currentEquivalenceTemp: [],
			filtering: false,

			filtersActive: {},
			clearfiltering: false,
		};
	},
	computed: {
		alertNotificationTitle() {
			return 'Exclusão da Equivalência';
		},
		alertNotificationDescription() {
			return `Excluir a equivalência <b>${ truncate(this.currentEquivalence.nome )}</b> apagará todas as parametrizações feitas e os registros adicionados, você não poderá recuperá-los. O que deseja fazer?`;
		},
		idEquivalence() {
			return this.$route?.params?.id;
		},
		workspace() {
			return this.$store.getters.workspace;
		},

	},
	async mounted() {
		this.$root.$on('reloadList', () => {
			this.loadCurrentEquivalence();
		});

		this.initEquivalenceDetailling();
	},
	created() {
		this.verifyPermissionUserAndInitiate();
	},

	methods: {
		...mapActions('aliareAccount', ['getPermissionFromUser']),

		async verifyPermissionUserAndInitiate() {
			this.isLoading = true;
			await this.validationAccessEquivalences().then(async () => {
				if (this.canAccessEquivalenceDetail()) {
					await this.loadCurrentEquivalence();
				} else {
					this.$store.commit('setData403', {show: true, prevPage: previousRoute});
				}
			});
		},
		async initEquivalenceDetailling() {
			const applyPage = WebStorage.getItem('applyPage');
			if (applyPage == 'equivalenceDetailling' ) {
				this.$router.replace({query: { ...this.$store.getters?.filters } }).catch(() => {});
			};

			if(Object.keys(this.$route.query).length) {
				this.filterTable();
			}
		},

		async filterBySearch(search) {
			this.filtering = search.length < 1 ? true : false;
			this.addOrRemoveFilter(this.filtersActive, 'filterSearch', search.length < 1 ? '' : search);
		},
		async filterTable(filters) {
			try {
				this.isLoading = true;
				const routeQuery = this.$route.query;
				const payload = {
					size: 10,
					pesquisa: filters?.filterSearch || routeQuery?.pesquisa,
				};

				this.$router.replace({query: { ...payload }}).catch(() => {});

				const dataFilter = await this.restApi().getEquivalenceRegister(this.workspace.id, this.currentEquivalence.id, payload);
				await this.applyFilter(dataFilter, payload);
			} catch (e) {
				this.$store.commit('activeAlert', {
					message: e.notifications[0],
					status: 'error',
				});
			} finally {
				this.isLoading = false;
			}
		},
		async applyFilter(dataFiltered, query) {
			this.filtering = true;
			this.isSearching = true;
			this.clearfiltering = true;
			this.searchingList = dataFiltered;

			this.$store.commit('setFilters', query);
			WebStorage.setItem('applyPage', 'equivalenceDetailling');
		},

		async clearFilter() {
			this.filtering = false;
			this.filtersActive = {};
			this.searchingList = '';
			this.isSearching = false;
			this.clearfiltering = false;

			await this.$router.replace({'query': null}).catch(() => {});

			this.$store.commit('setFilters', this.filtersActive);
			WebStorage.removeItem('applyPage');
    },
		addOrRemoveFilter(object, property, value) {
			if ((Array.isArray(value) && value.length === 0) || value === '') {
				this.$delete(object, property);
			} else {
				this.$set(object, property, value);
			}
		},

		openModalDelete(equivalence) {
			this.showAlert = true;
		},
		async reloadCurrentEquivalence() {
			await this.loadCurrentEquivalence();
		},

		editEquivalence() {
			this.$router.push({
				name: 'editEquivalence',
				params: { id: this.currentEquivalence.id },
			}).catch(()=>{});
		},

		onTabSelect(tab: string) {
			this.activeTab = tab;
		},
		editCredential () {
			this.$store.dispatch('ui/openExisitngCredential', { id: this.currentCredential.id});
		},
		async deleteEquivalence() {
			this.isLoadingAction = true;

			try {
				await this.restApi().deleteEquivalence(this.workspace.id, this.currentEquivalence.id);

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('equivalences.delete.successMessage', { interpolate: { deletedEquivalence: truncate(this.currentEquivalence.nome) }}),
					status: 'success',
				});

				this.$router.push({
					name: 'equivalences',
				});
			} catch (error) {
				this.$store.commit('activeAlert',  {
					message: this.$locale.baseText('equivalences.delete.failMessage'),
					status: 'error',
				});
			}

			this.isLoadingAction = false;
		},

		date(data) {
			const newDate = new Date(data);
			const novaData = `${newDate.toLocaleDateString()} às ${newDate.toLocaleTimeString()}`;
			return novaData;
		},

		async loadCurrentEquivalence() {
			this.isLoading = true;

			try {
				const currentEquivalence = await this.restApi().getEquivalenceById(this.workspace.id, this.idEquivalence);
				currentEquivalence.id = this.idEquivalence;
				this.currentEquivalence = currentEquivalence;
				this.currentEquivalenceTemp = this.currentEquivalence;
			} catch (error) {
				console.log(error);
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('credentialsList.errorOnLoadingCredentials'),
					status: 'error',
				});

				this.$router.push({
					name: 'equivalences',
				});
				return;
			} finally {
				this.isLoading = false;
			}
		},
	},
	beforeDestroy() {
	},
});
