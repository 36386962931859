<template>
	<loading v-show="isLoading" />
</template>

<script>
import WebStorage from '@/common/WebStorage';
import { searchPermissions, createNewAccount } from '@/components/helpers';

import { mapActions, mapGetters } from 'vuex';

import OidcService from '@/services/oidc.service';
import Loading from '@/components/Loading.vue';
import Alert from '@/components/Alert.vue';
import TokenService from '@/services/token.service';
import { OPERATION_TENANT, OPERATION_PROFILE } from '@/constants';

export default {
	components: {
		Alert,
		Loading,
	},
	async created() {
		this.isLoading = true;
		await this.signinCallback();
	},

	data() {
		return {
			showAlert: false,
			error: '',
			message: '',
			dismissSecs: 500000000000,
			dismissCountDown: 0,
			show_Alert: false,
			loginByOidc: false,
			keepAccess: false,
			account: [],
			dataPermission: [],
			listPermission: [],
			accountData: [],
			isLoading: false,
			userId: '',
		};
	},
	computed: {
	},

	mounted() {
	},

	methods: {
		...mapActions('aliareAccount', ['getUserAccountId', 'getPermissionFromUser', 'getAccountDataV2', 'getAccountTenantV2', 'getAccountTenant', 'getUserPermissionById']),
		...mapActions('auth', ['authenticateUser', 'getUserInfo', 'revokeTokens', 'logout']),

		parseJwt(token) {
			return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
		},

		async signinCallback() {
			if (this.$route.query.error) {
				console.error(this.$route.query.error);
				return;
			}
			await OidcService.signinRedirectCallback().then(async (result) => {
				TokenService.setTokens(result);
				const token = TokenService.getTokens();
				this.userId = this.parseJwt(token.access_token);

				if(this.userId.Level === 'N2') {
					await this.getAccountId();

				} else {
					await this.checkN1OrN0Permission();
				}
			}).catch((e) => {
				console.error(e);
				this.$router.push('/login');
			});
		},

		logoutAccount() {
			this.logout();
		},
		async getAccountId() {
			try {
				const resultUserAccount = await this.getUserAccountId(this.userId.sub);

				this.accountData = resultUserAccount.data
					.filter(account => account.active && !account.blocked);

				if (this.accountData.length === 0) {
					this.isLoading = false;
					this.logoutAccount();
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('login.noPermission'),
						status: 'error',
					});
				} else {
					this.verifyAccount();
				}
			} catch (error) {
				console.error('Error fetching user account ID:', error);
			}
		},
		async verifyAccount() {

			if (this.accountData.length >= 2) {
				this.getMultipleAccounts();
				return;
			}

			this.verifyPermission();
		},

		async getMultipleAccounts() {

			try {
				const multiAccount = await Promise.all(
					this.accountData.map(async (account) => {
						const response = await this.getAccountDataV2(account?.tenantId);

						if (response.active && !response.blocked) {
							return { ...response, account };
						}
					})
				);
				const activeAccounts = await this.verifyOrganizationsActive(multiAccount);


				WebStorage.setItem('tokenData', this.userId);
				WebStorage.setItem('cardOrganization', JSON.stringify(activeAccounts));
				this.$router.push('/organizacao');
				this.isLoading = false;
			} catch (e) {
				console.error(e);
				this.isLoading = false;

				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('Ocorreu um erro ao carregar as organizações. Tente novamente em alguns instantes'),
					status: 'error',
				});
			}
		},
		async verifyOrganizationsActive(orgs, pushValue) {
			if (!orgs.length) return;

			const actualTenant = WebStorage.getItem('tenantId');
			const orgsVerified = [];

			for (const organization of orgs) {
				WebStorage.setItem('tenantId', organization.id);

				const response = await this.getUserPermissionById({ userId: organization.account.userId });
				const activePermission = response.some(permission => !permission.userProfile.blocked && permission.userProfile.active);
				organization.activePermission = activePermission;

				if (activePermission) {
					orgsVerified.push(organization);
				}
			}

			const orgsActive = pushValue ? [...orgsVerified] : orgsVerified;
			WebStorage.setItem('tenantId', actualTenant);
			return orgsActive;
		},

		async verifyPermission() {
			try {
				const result = await searchPermissions(this.accountData[0].userId, this.accountData[0].tenantId);

				if (result) {
					WebStorage.setItem('tenantId', this.accountData[0].tenantId);

					let accountInfo = createNewAccount(this.accountData[0]);
					const response = await this.getAccountDataV2(this.accountData[0].tenantId);
					accountInfo = {
						...accountInfo,
						principalAdminUser: response.principalAdminUser,
						adminUsers: response.adminUsers,
					}
					
					WebStorage.setItem('tokenData', this.userId);
					WebStorage.setItem('accountInfo', JSON.stringify(accountInfo));
					WebStorage.setItem('accountName', this.accountData[0].accountName);
					WebStorage.setItem('cardOrganization', JSON.stringify(this.accountData));

					this.$router.push({
						name: 'workspace',
					});
					return this.isLoading = false;
				}

				this.isLoading = false;
				this.logoutAccount();

				return this.$store.commit('activeAlert', {
					message: this.$locale.baseText('login.noPermission'),
					status: 'error',
				});

			} catch (error) {
				this.isLoading = false;

				this.$store.commit('activeAlert', {
					message: error.notifications,
					status: 'error',
				});
			}
		},

		async checkN1OrN0Permission() {
			try {
				const response = await searchPermissions(this.userId.sub, this.userId.Tenant);

				if(response) {
					this.userId.hasPermission = true;
					this.getUserN1OrN0();
					return;
				}

				this.accountData = [];
				this.userId.hasPermission = false;
				this.getAccountId();
			} catch (error) {
				this.isLoading = false;

				this.logoutAccount();
				this.$store.commit('activeAlert', {
					message: this.$locale.baseText('login.noPermission'),
					status: 'error',
				});
			}
		},
		async getUserN1OrN0() {
			try {
				const payload = { pageSize: 15, page: 1 };
				const response = await this.getAccountTenantV2(payload);
				const data = response.data.filter(item => item.active && !item.blocked);

				WebStorage.setItem('tokenData', this.userId);
				WebStorage.setItem('cardOrganization', JSON.stringify(data));
				this.$router.push('/organizacao');
				this.isLoading = false;
			} catch (error) {
				console.error("Error fetching or processing data:", error);
				this.isLoading = false;
			}
		},
	},
};
</script>

