<template>
	<div>
		<Modal
			:name="IMPORT_INTEGRATION_MODAL_KEY"
			:eventBus="modalBus"
			:title="'Importar Integração'"
			:closeOnClickModal="false"
			width="965px"
			minHeight="431px"
			footerOut
		>
			<template v-slot:content>
				<div class="info-container">
					<InfoBox>
						<template #content>
							<p>
								{{ infoText }}
							</p>
						</template>
					</InfoBox>
				</div>

				<div class="container-loading" v-if="isLoading">
					<div class="loading-spin"></div>
				</div>

				<div v-else>
					<div class="container-select">
						<el-select
							v-model="selectedWorkspace"
							filterable
							:disabled="false"
							:no-data-text="$locale.baseText('Não encontramos nenhum item com este nome')"
							:no-match-text="$locale.baseText('Não encontramos nenhum item com este nome')"
							:placeholder="''"
							@change="onSelectWorkspace"
							ref="select"
							value-key="id"
							@focus="isFocused = true"
							@blur="isFocused = false"
						>
							<label slot="prefix" :class="{ focused: isFocused || Object.keys(selectedWorkspace).length }" class="labelInput">
								{{ 'Espaço de Trabalho *' }}
							</label>

							<el-option
								v-for="workspace in listWorkspace"
								:value="workspace"
								:key="workspace.id"
								:label="workspace.nome"
								filterable
							/>

							<ScrollInfinite v-if="scrollInfinite" @endOfScroll="paginations()" />
						</el-select>
					</div>

					<div>
						<AliareInput
							required
							:maxlength="50"
							:blockInput="!Object.keys(selectedWorkspace).length"
							:labelText="labelProject"
							v-model="projectName"
							@input="verifyNameProject"
						/>

						<div v-if="projectValidName && projectName !== ''">
							<p class="messageInfo success">O Espaço de Trabalho está disponível.</p>
						</div>
						<div v-else-if="projectValidName !== '' && projectName !== ''">
							<p class="messageInfo error">O Espaço de Trabalho está indisponível.</p>
						</div>
					</div>

					<div class="container-title" v-if="mode == 'multiple'">
						<div
							v-for="item in listProjects"
							:key="item.id"
						>
							<p>
								{{ prefix }} {{ item.nome }}
							</p>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:footer>
				<div class="footer-buttons">
					<ModalButtonSuccess
						importIcon
						width="180"
						@click="saveImport()"
						:alwaysActive="isCompleteFields"
						:titleButton="'Importar Integração'"
					/>

					<div class="divider" />

					<ModalButtonCancel
						@click="modalBus.$emit('close')"
						width="111"
					/>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import Modal from './Modal.vue';
import InfoBox from '@/components/InfoBox.vue';
import AliareInput from '@/components/AliareInput.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';

import Vue from 'vue';
import { mapActions } from 'vuex';
import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';

import WebStorage from '@/common/WebStorage';
import { IMPORT_INTEGRATION_MODAL_KEY } from '@/constants';

export default mixins(
	restApi,
).extend({
	name: 'ImportIntegration',
	components: {
		Modal,
		InfoBox,
		AliareInput,
		ScrollInfinite,
		ModalButtonCancel,
		ModalButtonSuccess,
	},
	props: {
		dataModal: {
			type: Object,
		},
		mode: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			modalBus: new Vue(),
			IMPORT_INTEGRATION_MODAL_KEY,

			page: 1,
			pageSize: 25,
			projectName: '',
			isLoading: false,
			isFocused: false,
			listProjects: [],
			listWorkspace: [],
			projectValidName: '',
			scrollInfinite: true,
			selectedWorkspace: {},
			tenantId: WebStorage.getItem("tenantId"),
		};
	},
	created() {
		this.loadModal();
	},
	computed: {
		infoText() {
			return this.mode == 'unique' ? 'Ao importar essa integração, um novo projeto será criado dentro do Espaço de Trabalho selecionado. Aqui você pode definir o titulo desse novo projeto.'
			: 'Ao importar essa integração, novos projetos serão criados dentro do Espaço de Trabalho selecionado. Aqui você pode definir um prefixo que antecederá os titulos originais desses projetos.';
		},
		labelProject() {
			return this.mode == 'unique' ? 'Titulo do Projeto' : 'Prefixo do Projeto';
		},
		prefix() {
			if(this.projectName !== '') {
				return this.projectName + ' - ';
			}
			return '';
		},
		isCompleteFields() {
			if (this.mode === 'unique') {
				return this.projectName !== '' &&
							Object.keys(this.selectedWorkspace).length > 0 &&
							this.projectValidName !== '' &&
							this.projectValidName !== false;
			} else if (this.mode === 'multiple') {
				return Object.keys(this.selectedWorkspace).length > 0;
			}
			return false;
		},
		organizationId() {
            return WebStorage.getItem('tenantId');
        }
  },
	methods: {
		onFocus() {
			this.isFocused = !this.isFocused;
		},
		onSelectWorkspace(type) {
			this.selectedWorkspace = type;
			this.verifyNameProject();
		},
		verifyNameProject:
			_.debounce(async function (e)  {
				if(this.mode !== 'unique') return;

				if(this.projectName != '' && this.projectName.length > 2) {
					const nameUnavailable = await this.restApi().checkAvailableNameProject(this.selectedWorkspace.id, {
							nome: this.projectName,
					});

					if (nameUnavailable) {
						this.projectValidName = false;
					} else {
						this.projectValidName = true;
					}
				} else {
					this.projectValidName = '';
				}
			},
		500),

		async loadModal() {
			this.isLoading = true;
			if(this.mode == 'unique') {
				this.projectName = this.dataModal.projetos[0].nome;
			}

			this.listProjects = [...this.dataModal.projetos];
			await this.loadWorkspaces();
			this.isLoading = false;
		},
		async loadWorkspaces() {
			try {
				this.listWorkspace = await this.restApi().getWorkspaces({ page: 1, size: this.pageSize });

				if (this.listWorkspace.length < 25) {
					this.scrollInfinite = false;
				}

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: error.notifications,
					status: 'error',
				});
				this.scrollInfinite = false;
			}
		},
		async paginations() {
			this.page++;
			this.scrollInfinite = true;
			const payload = { page: this.page, size: this.pageSize };

			try {
				const response = await this.restApi().getWorkspaces(payload);
				this.listWorkspace.push(...response);

				if (response.length < 25) {
					this.scrollInfinite = false;
				}
			} catch (error) {
				console.error(error);
			}
		},
		async saveImport() {
			const payload = {
				workspaceId: this.selectedWorkspace.id,
				tituloProjeto: this.mode == 'unique' ? this.projectName : '',
				prefixoProjeto: this.mode == 'multiple' ? this.projectName : '',
			};

			try {
				this.modalBus.$emit('close');
				this.$store.commit('activeAlert', {
					message: `Iniciamos a importação da integração, isso poderá levar alguns minutos. Informaremos quando concluímos.`,
					status: 'info',
				});

				await this.restApi().importPublishedIntegrations(this.dataModal.id, payload);

				this.$store.commit('activeAlert', {
					message: `Importação concluída com sucesso! Os projetos estão disponíveis no seu Espaço de Trabalho.`,
					link: {
						message: `Acessar Espaço de Trabalho`,
						src: `/organizacao/${this.organizationId}/espacos-de-trabalho/${this.selectedWorkspace.id}/projetos`,
					},
				});
				this.$root.$emit('reloadList');

			} catch (error) {
				this.$store.commit('activeAlert', {
					message: `A Importação da Integração falhou. Você pode fazer uma nova tentativa a partir da sua lista de Solicitações no Catálogo.`,
					status: 'error',
				});
			}
		},
	},
});
</script>

<style lang="scss" scoped>
.info-container {
	padding: 0px 0px 30px 0px;
}
.container-title {
	padding: 24px 0;
	color: rgba(255, 255, 255, 0.88);
}
.container-select {
	padding: 0px 0px 30px 0px;
}

.text-workspace {
	font-size: 14px;
	font-weight: 500;
	margin: 0px 0 6px 0;
	color: rgba(255, 255, 255, 0.66);
}
.footer-buttons {
  width: 100%;
  display: flex;
  justify-items: center;
  justify-content: flex-end;
	border-top: 1px solid #385386;
  padding: 10px 11px 15px 0px;
}
.divider {
	width: 1px;
	height: 40px;
	background: #D3D3D3;
	margin: 0 15px;
}
.labelInput {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transition: all .4s;
	width: max-content;
	line-height: 1;
	font-size: 14px;
	padding: 0 5px;
	left: -7px;
	color: rgba(255, 255, 255, 0.56);

	&.focused {
		background: #1C2638;
		color: #00C3DD;
		top: -1px;
		font-size: 13px;
	}
}
.messageInfo {
	font-size: 14px;
	margin-left: 4px;
	margin-bottom: auto;
	overflow-wrap: anywhere;

	&.success {
		color: #59D64C;
	}
	&.error {
		color: #FF495F;
	}
	&.limit {
		color: #ffa53b;
	}
}
.container-loading {
	display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  height: 200px;

	.loading-spin {
		width: 120px;
		height: 120px;
		background-image: url('../assets/loading-modal.png');
		background-repeat: no-repeat;
		background-size: cover;
		animation: spin 2s linear infinite;
	}
	@keyframes spin {
		100% {
			transform: rotate(360deg);
		}
	}
}

:deep {
	.modal-content {
		background: #1C2638;
	}

	.container-info {
		background: #0C32BB;
	}

	.container-info p {
    color: rgba(255, 255, 255, 0.88);
		font-size: 14px;
		font-weight: 400;
	}

	.container-info .icon-info {
		margin: 0px 5px;
    background-image: url('../assets/roundedExclamationWhite.svg');
	}

	.el-input.is-disabled {
		input {
			background: rgba(255, 255, 255, 0.08);
			border: none;
		}
	}
	.el-input__inner {
		padding: 0 7px;
		color: rgba(255, 255, 255, 0.88);
		background: #223049;
		height: 40px;
	}

	.aliareInput {
		&--input {
			&:disabled {
				border: none;
				cursor: not-allowed;
				background: rgba(255, 255, 255, 0.08);
			}
		}
	}
}
</style>
