
import Modal from '@/components/ModalAliare.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import { mapActions } from 'vuex';
import CardInfo from '@/components/CardInfo.vue';
import CardFlow from '@/components/CardFlow.vue';
import AliareSelect from '@/components/AliareSelect.vue';
import AliareInput from '@/components/AliareInput.vue';
import { error } from 'n8n-workflow/dist/src/LoggerProxy';
import SelectTree from '@/components/SelectTree.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import { searchPermissions } from '@/components/helpers';
import { PERMISSION_IDS, CREATE_EDIT_PROJECT_MODAL_KEY} from '@/constants';
import { projectPermission } from '@/components/mixins/permissions/project';
import { genericHelpers } from '@/components/mixins/genericHelpers';

import mixins from 'vue-typed-mixins';

import WebStorage from '@/common/WebStorage';

import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import Loading from '@/components/Loading.vue';

export default mixins(
	projectPermission,
	genericHelpers,
).extend({
	name: 'Project',
	components: {
		Modal,
		CardInfo,
		CardFlow,
		ModalButtonSuccess,
		ModalButtonCancel,
		AliareSelect,
		AliareInput,
		SelectTree,
		ScrollInfinite,
		Loading
	},
	data() {
		return {
			value: null,
			selectedValue: '2',
			message: '',
			account: [],
			optionList: [
				{
					id: 1,
					label: 'Todos',
				},
				{
					id: 2,
					label: 'Ativo',
				},
				{
					id: 3,
					label: 'Inativo',
				},
			],

			resultOptions: [
				{
					id: 1,
					label: 'Todos os Resultados',
				},
				{
					id: 2,
					label: 'Resultados com Sucesso',
				},
				{
					id: 3,
					label: 'Resultados com Alerta',
				},
				{
					id: 4,
					label: 'Resultados com Falha',
				},
			],
			pageSize: 15,
			page: 1,
			scrollInfinite: true,
			resultDetails: '',
			dataTotalizers: [],
			dataTenant: [],
			intervalTimer: null,
			status: 'active',
			statusExecution: 'all',

			tokenData: '',
			isLoading: false,
			isLoadingCards: false,
		};
	},
	async created() {
		this.tokenData = WebStorage.getItem('tokenData');
		await this.getInformations();
	},
	mounted() {
		this.intervalTimer = window.setInterval(() => {
			this.getAccount();
		}, 300000);

		this.initializeTrackSale();

		this.$root.$on("reloadProject", this.eventHandler);
	},
	destroyed () {
		// Make sure that the timer gets destroyed once no longer needed
		if (this.intervalTimer !== null) {
			clearInterval(this.intervalTimer);
		}
	},
	computed: {
		organization() {
			return JSON.parse(WebStorage.getItem('cardOrganization'));
		},
		accountInfo() {
			return JSON.parse(WebStorage.getItem('accountInfo'));
		},
		accountName() {
			return WebStorage.getItem('accountName');
		},
		workspace() {
			return JSON.parse(WebStorage.getItem('workspace'));
		},
	},
	methods: {
		...mapActions('project', [
			'getProjectDetails',
			'getProject',
			'postProject',
			'delete',
			'getProjectTotalizers',
		]),
		...mapActions('variable', ['getVariableId',]),
		...mapActions('aliareAccount', ['getUserAccountId',]),
		...mapActions('auth', ['revokeTokens']),

		async getInformations() {
			const isFirstTime = WebStorage.getItem('isFirstTime');

			if (!Object.keys(this.workspace).length) {
				this.$router.push({
					name: 'workspace',
				});
				return;
			}

			if (isFirstTime === null) {
				this.isLoading = true;
				this.isLoadingCards = true;
				await Promise.all([this.getAccount(), this.totalizers()]);
				this.isLoadingCards = false;
				this.isLoading = false;

				WebStorage.setItem('isFirstTime', 'false');
			} else {
				this.isLoadingCards = true;
				await Promise.all([this.getAccount(), this.totalizers()]);
				this.isLoadingCards = false;
			}
		},

		async paginations() {
			if (!this.scrollInfinite) {
				return;
			}

			this.page++;

			let payload = null;

			switch (this.statusExecution) {
				case 'all':
					payload = { workspaceId: this.workspace.id, page: this.page, size: this.pageSize };
					break;
				case 'success':
					payload = { workspaceId: this.workspace.id, page: this.page, size: this.pageSize, resultadoExecucao: 'Sucesso' };
					break;
				case 'alert':
					payload = { workspaceId: this.workspace.id, page: this.page, size: this.pageSize, resultadoExecucao: 'Alerta' };
					break;
				case 'fail':
					payload = { workspaceId: this.workspace.id, page: this.page, size: this.pageSize, resultadoExecucao: 'Falha' };
					break;
				default:
					break;
			}

			switch (this.status) {
				case 'active':
					payload.ativo = true;
					break;
				case 'inactive':
					payload.ativo = false;
					break;
				default:
					break;
			}

			

			try {
				const response = await this.getProjectDetails(payload);

				if (!response?.data?.length) {
					this.scrollInfinite = false;
					this.page = 1;
					this.pageSize = 15;
					return;
				}
				const projectInfinite = response.data;

				this.resultDetails.data.push(...projectInfinite);
			} catch (error) {
				console.error(error);
				this.scrollInfinite = false;
			}
		},

		totalizers() {
			const tenantId = WebStorage.getItem('tenantId');
			setupHeaderAliareTenant(tenantId);

			this.getProjectTotalizers({workspaceId: this.workspace.id}).then((resultTotalizers) => {
				this.dataTotalizers = resultTotalizers.data;
			});
		},

		async onStatus(type: string) {
			this.isLoadingCards = true;
			const tenantId = WebStorage.getItem('tenantId');
			setupHeaderAliareTenant(tenantId);
			this.resultDetails = '';
			let response = [];
			this.page = 1;

			try {
				const resultadoExecucao = this.statusExecution == 'all' ? '' : this.statusExecution == 'success' ? 'Sucesso' : this.statusExecution == 'alert' ? 'Alerta' : this.statusExecution == 'fail' ? 'Falha' : '';

				switch (type.id) {
					case 1:
						this.status = 'all';
						response = await this.getProjectDetails({workspaceId: this.workspace.id, page: 1, size: this.pageSize, resultadoExecucao });
						break;
					case 2:
						this.status = 'active';
						response = await this.getProjectDetails({workspaceId: this.workspace.id, page: 1, size: this.pageSize, ativo: true, resultadoExecucao });
						break;
					case 3:
						this.status = 'inactive';
						response = await this.getProjectDetails({workspaceId: this.workspace.id, page: 1, size: this.pageSize, ativo: false });
						break;
					default:
						break;
				}

				if (response.data.length == 0) {
					this.scrollInfinite = false;
				} else {
					this.scrollInfinite = true;
				}

				this.resultDetails = response;

			} catch (error) {
				console.log('error', error);
			}

			this.isLoadingCards = false;
		},

		async onResult(type: string) {
			this.isLoadingCards = true;

			const tenantId = WebStorage.getItem('tenantId');
			setupHeaderAliareTenant(tenantId);
			this.resultDetails = '';
			let response = [];
			this.page = 1;

			const statusProjeto = this.status == 'all' ? '' : this.status == 'active' ? true : this.status == 'inactive' ? false : '';


			try {
				switch (type.id) {
					case 1:
						this.statusExecution = 'all';
						response = await this.getProjectDetails({workspaceId: this.workspace.id, page: 1, size: this.pageSize, ativo: statusProjeto});
						break;
					case 2:
						this.statusExecution = 'success';
						response = await this.getProjectDetails({workspaceId: this.workspace.id, page: 1, size: this.pageSize, resultadoExecucao: 'Sucesso', ativo: statusProjeto });
						break;
					case 3:
						this.statusExecution = 'alert';
						response = await this.getProjectDetails({workspaceId: this.workspace.id, page: 1, size: this.pageSize, resultadoExecucao: 'Alerta', ativo: statusProjeto });
						break;
					case 4:
						this.statusExecution = 'fail';
						response = await this.getProjectDetails({workspaceId: this.workspace.id, page: 1, size: this.pageSize, resultadoExecucao: 'Falha', ativo: statusProjeto });
						break;
					default:
						break;
				}

				this.resultDetails = response;
				this.scrollInfinite = true;
			} catch (error) {
				console.log('error', error);
			}

			this.isLoadingCards = false;

		},

		async getAccount(sizeInfinite) {
			if (sizeInfinite) {
				this.pageSize = 99999;
				this.scrollInfinite = false;
			}
			const status = this.status == 'all' ? '' : this.status == 'active' ? true : false;
			const tenantId = WebStorage.getItem('tenantId');
			setupHeaderAliareTenant(tenantId);
			this.page = 1;

			await this.getProjectDetails({workspaceId: this.workspace.id, page: this.page, size: this.pageSize, ativo: status })
			.then((responseAccount) => {
				if (sizeInfinite) {
					responseAccount.data.forEach((project) => {
						if (project.permissao == 'Editar') {
							project.canShare = this.canShareProject(project, project.ativo),
							project.selectedProject = true;
						}
					});
					this.resultDetails = '';
					this.resultDetails = responseAccount;
					this.status = 'active';

				} else {
					this.scrollInfinite = true;
					this.resultDetails = responseAccount;
					this.status = 'active';
				}

			}).catch((error) => {
				const ErrorProject = error.response?.data?.notifications;
				this.$store.commit('activeAlert', {
					message:  this.$locale.baseText(error.response?.data?.notifications),
					status: 'error',
				});
			});

			this.pageSize = 15;
		},
		async eventHandler() {
			this.isLoadingCards = true;
			this.resultDetails = '';
			this.status = 'active';
			this.statusExecution =  'all',
			await this.getAccount();
			this.isLoadingCards = false;
		},
		showModals(key) {
			if (key === 'createEditProject') {
				this.$store.dispatch('ui/openCreateEditProjectModal', { mode: 'create' });
			}
		},
	},
	beforeDestroy() {
		this.$root.$off("reloadProject", this.eventHandler);
	},
});
