<template>
	<div>
		<background-view :isLoading="isLoadingPage">
			<AlertNotificationModal
				v-if="showAlert"
			>
				<template v-slot:icon>
					<i class="equivalenceCreateSuccessIcon"></i>
				</template>

				<template v-slot:title>
					{{ alertNotificationTitle }}
				</template>
				<template v-slot:firstText>
					<p v-html="alertNotificationDescription"></p>
				</template>

				<template v-slot:footer>
					<ModalButtonSuccess
						:titleButton="alertNotificationTitleCancel"
						width="300"
						@click="showAlert = false"
						alwaysActive
						plusIcon
					/>
					<ModalButtonCancel
						width="300"
						:loadingAction="isLoadingAction"
						:titleButton="alertNotificationTitleConfirm"
						@click="notificationDelete ? deleteVariable() : resetModal()"
					/>
				</template>
			</AlertNotificationModal>

			<interaction-menu
				:InteractionMenuTitle="interactionMenu__titleHeader"
				:interactionMenuPlaceHolder="interactionMenu__headerDropdownPlaceHolder"
				:interactionMenuButtonToRegisterVariableBoolean="true"
				:interactionMenuButtonToRegisterVariable="interactionMenu__buttonToRegisterVariable"
				:interactionMenuLink="interactionMenu__link"
				:alwaysActive="canCreateVariable()"
				:viewClearFilter="false"
			></interaction-menu>
			<div class="dropdown">
				<div class="dropdown__titleBox">
					<div class="dropdown__title">
						<div class="dropdown__titleText">{{ $locale.baseText(dropdown__Title) }}</div>
					</div>
				</div>
				<div class="dropdown__bodyBox">
					<div class="dropdown__dropdownRowShow" v-for="dropdown in flowData" :key="dropdown.id">
						<div class="dropdown__dropdownRow">
							<div
								class="dropdown__dropdownRowTitle"
								v-on:click="getTable(dropdown)"
								@click="triggerDropdown(dropdown)"
							>
								<div
									class="dropdown__dropdownRowTitleArrowIcon"
									v-bind:class="{
										dropdown__dropdownRowTitleActiveArrowIcon: showDropdown === dropdown,
									}"
								></div>
								<div>
									<div class="dropdown__dropdownRowTitleText">
										{{ dropdown.nome }}
									</div>
									<div class="dropdown__dropdownRowSubtitleText">
										{{ dropdown.projeto.nome }}
									</div>
								</div>
							</div>
							<el-tooltip :disabled="canAddVariable(dropdown.ativo)" :content="dropdown.ativo ? $locale.baseText('permission.noPermissionFuncionality') : 'O fluxo está inativo e não pode ter variáveis adicionadas'"
								placement="bottom"
							>
								<div
									class="dropdown__dropdownRowButton"
									@click="!canAddVariable(dropdown.ativo) ? '' : (openModalNewVariable(dropdown), notificationDelete = false)"
									:class="{ 'dropdownDisable': !canAddVariable(dropdown.ativo) }"
								>
									<div class="dropdown__dropdownRowButtonIcon" :class="{ 'dropdownIconDisable': !canAddVariable(dropdown.ativo) }"/>
									<div class="dropdown__dropdownRowButtonText" :class="{ 'dropdownTextDisable': !canAddVariable(dropdown.ativo) }">
										{{ $locale.baseText(dropdown__TextButton) }}
									</div>
								</div>
							</el-tooltip>
						</div>
						<div v-if="showDropdown === dropdown" class="dropdown__dropdownRowShowTable">
							<interaction-menu
								v-if="variableTable.length"
								:interactionMenuButtonToCleanFilter="interactionMenu__buttonToCleanFilter"
								:interactionMenuFilter="filtering"
								@clearFilter="clearFilter"
								onlyClearFilter
								>
							</interaction-menu>

							<Table
								:lastColumnWidth__Boolean="true"
								:filterIcon="false"
								:tableOverflow="true"
								:isLoading="isLoadingTable"
								:interactionMenuFilter="filtering"
							>
								<template v-slot:headerColumns>
									<tr class="table__tableTheadTr">
										<th class="table__tableTheadTrTh">
											<div class="table__tableTheadTrThTitle">
												<div class="table__tableTheadTrThTitleText">
													{{ $locale.baseText(table__Variable) }}
												</div>
												<FilterTable
													:applyedFilter="applyedFilters.includes('filterByNameVariable')"
												>
													<template v-slot:filter>
														<VariableFilter
															filter="name"
															:clearInput="filtering"
															:workflowId="showDropdown.id"
															:placeholder='$locale.baseText("searchBar.equivalences.filters.placeholderOrigin")'
															@selectFilter="filterByNameVariable"
														/>
													</template>
												</FilterTable>
											</div>
										</th>
										<th class="table__tableTheadTrTh">
											<div class="table__tableTheadTrThTitle">
												<div class="table__tableTheadTrThTitleText">
													{{ $locale.baseText(table__Value) }}
												</div>
												<FilterTable
												:applyedFilter="applyedFilters.includes('filterByValueVariable')"
											>
												<template v-slot:filter>
													<VariableFilter
														filter="value"
														:clearInput="filtering"
														:workflowId="showDropdown.id"
														:placeholder='$locale.baseText("searchBar.equivalences.filters.placeholderOrigin")'
														@selectFilter="filterByValueVariable"
													/>
												</template>
											</FilterTable>
											</div>
										</th>

										<th
											class="table__tableTheadTrTh"
											v-bind:class="{ table__tableTheadTrThLastColumn: false }"
										>
											<div class="table__tableTheadTrThTitle">
												<div class="table__tableTheadTrThTitleText">
													{{ $locale.baseText(table__Actions) }}
												</div>
												<div v-bind:class="{ table__tableTheadTrThTitleFilterIcon: false }"></div>
											</div>
										</th>
									</tr>
								</template>
								<template v-slot:columns>
									<tr v-for="column in variableTable" :key="column.id">
										<td class="break-word">{{ column.nome }}</td>
										<td class="break-word">{{ column.valor }}</td>
										<td class="table__tableTbodyLastColumn">
											<div class="table__tableTbodyLastColumnAction">
												<el-tooltip :disabled="canEditVariable(dropdown.ativo)"	:content="dropdown.ativo ? $locale.baseText('permission.noPermissionFuncionality') : 'O fluxo está inativo e não pode ter variáveis modificadas'"
													placement="bottom"
												>
													<div
														class="table__tableTbodyLastColumnActionEdit"
														:class="{ 'disableBtn--edit': !canEditVariable(dropdown.ativo) }"
														@click="!canEditVariable(dropdown.ativo) ? '' : (openModalEditVariable(dropdown, column), notificationDelete = false)"
													/>
												</el-tooltip>

												<el-tooltip :disabled="canDeleteVariable(dropdown.ativo)"	:content="dropdown.ativo ? $locale.baseText('permission.noPermissionFuncionality') : 'O fluxo está inativo e não pode ter variáveis modificadas'"
													placement="bottom"
												>
													<div
														class="table__tableTbodyLastColumnActionDelete"
														:class="{ 'disableBtn--delete': !canDeleteVariable(dropdown.ativo) }"
														@click.stop="!canDeleteVariable(dropdown.ativo) ? '' : (notificationDelete = true, variableData = column, showAlert = true)"
													>
													</div>
												</el-tooltip>
											</div>
										</td>
									</tr>
								</template>
							</Table>
						</div>
					</div>
					<scroll-infinite
						@endOfScroll="pagination()"
						v-if="(scrollInfinite && flowData.length)"
					/>
				</div>
			</div>
		</background-view>

		<modal :show="showModal" :width_container="width" :height_container="height">
			<template #header>
				<span>
					{{ newVariable ? $locale.baseText('variable.interactionMenu__buttonToRegisterVariable') : $locale.baseText('variable.modal__EditHeader') }}
				</span>
				<div @click="openModalAlert()" class="modal__headerIcon" ></div>
			</template>
			<template #body>
				<div class="createVariable__bodySecondSectionSelectDiv" v-click-outside="disableInput">
					<button
						class="createVariable__bodySecondSectionSelect"
						:class="{ blockedSelect: newVariable}"
						@click="activeSearch()"
						@keyup.esc="showSelect = !showSelect"
						v-if="!showSelect"
					>
						<div>{{ workflowSelected.nome }}</div>
						<div class="createVariable__bodySecondSectionSelectOptionsProject">{{ workflowSelected.projeto.nome }}</div>
					</button>
					<AliareInput
						className="input-custom--createVariable"
						labelText="Fluxos e seu Projeto"
						v-model="queryInput"
						@input="searchInfoByName()"
						v-show="(showSelect && !newVariable)"
						ref="aliareInput"

					/>
					<div
						v-show="(workflowList.length && showSelect)"
						class="createVariable__bodySecondSectionSelectOptionsBackground"
					>
						<div
							class="createVariable__bodySecondSectionSelectOptions"
							v-for="info in workflowList"
							:key="info.nome"
							@click="
								(showSelect = !showSelect),
								(selectedProject = info.projeto.nome),
								getVariable(info),
								verifyVariable,
								(workflowSelected = info)
							"
						>
							<div class="createVariable__bodySecondSectionSelectOptionsFlow">
								{{ info.nome }}
							</div>
							<div class="createVariable__bodySecondSectionSelectOptionsProject">
								{{ info.projeto.nome }}
							</div>
							<div class="createVariable__bodySecondSectionLine"></div>
						</div>
					</div>
				</div>

				<Transition name="fadeHeight" mode="out-in">
					<div class="createVariable__bodySecondSectionVariable" v-if="workflowSelected">
						<div class="createVariable__bodySecondSectionVariableFirstColumn">
							<AliareInput
								labelText="Variável"
								v-model="variable"
								@input="verifyVariable"
							/>
							<div
								v-if="validation && variable"
								class="createVariable__bodySecondSectionVariableVariableSuccess"
							>
								O título da Variável está disponível para uso
							</div>
							<div
								v-if="!validation && variable"
								class="createVariable__bodySecondSectionVariableVariableError"
							>
								O Título da variável não está disponível para uso
							</div>
						</div>
						<div class="createVariable__bodySecondSectionVariableFirstColumn">
							<AliareInput
								labelText="Valor"
								v-model="variableValue"
							/>
						</div>
					</div>
				</Transition>
			</template>
			<template #footer>
				<ModalButtonSuccess
					:saveIcon="true"
					@click="variablePost"
					v-if="newVariable"
					:active="!!variable && !!workflowSelected && !!selectValue && !!validation"
					titleButton="Concluir"
					width="97"
				/>
				<ModalButtonSuccess
					:saveIcon="true"
					v-if="!newVariable"
					@click="patchVariable"
					:active="!!variable && !!workflowSelected && !!selectValue && !!validation"
					:titleButton="$locale.baseText('credentialDetailing.actions.edit')"
					width="97"
				/>

				<ModalButtonCancel
					@click="openModalAlert()"
					width="97"
				/>
			</template>
		</modal>
	</div>
</template>

<script>
import VariableFilter from '@/components/VariableFilters.vue';
import ScrollInfinite from '../components/ScrollInfinite.vue';
import FilterTable from '@/components/FilterTable.vue';
import AliareSelect from '@/components/AliareSelect.vue';
import AliareInput from '@/components/AliareInput.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import Modal from '@/components/ModalAliare.vue';
import BackgroundView from '@/components/BackgroundView.vue';
import Table from '@/components/Table.vue';
import InteractionMenu from '@/components/InteractionMenu.vue';
import { mapActions, mapGetters } from 'vuex';
import { error } from 'n8n-workflow/dist/src/LoggerProxy';
import Alert from '@/components/Alert.vue';
import TokenService from '@/services/token.service';
import { setupHeaderAliareTenant } from '@/config/axiosConfig';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

import mixins from 'vue-typed-mixins';
import { PERMISSION_IDS,} from '@/constants';
import { restApi } from '@/components/mixins/restApi';
import { showMessage } from '@/components/mixins/showMessage';
import { variablePermission } from '@/components/mixins/permissions/variable';
import { removeItemFromArrayByValue, filter, searchPermissions } from '@/components/helpers';

import WebStorage from '@/common/WebStorage';

export default mixins(
	restApi,
	showMessage,
	variablePermission,
).extend({
	name: 'Project',
	components: {
		Modal,
		BackgroundView,
		Table,
		InteractionMenu,
		Alert,
		AliareSelect,
		AliareInput,
		ModalButtonSuccess,
		ModalButtonCancel,
		ScrollInfinite,
		FilterTable,
		VariableFilter,
		AlertNotificationModal,
	},
	data() {
		return {
			interactionMenu__link: 'createvariable',
			selectValue: '',
			showDropdown: '',
			width: '520px',
			height: '420px',
			widthDelete: '294px',
			heightDelete: '285px',
			background_headerDelete: '#141722',
			borderBottom_headerDelete: 'none',
			background_bodyDelete: '#141722',
			border_containerDelete: '2px solid #FF495F',
			shadow_containerDelete: '-2px -2px 4px #FF495F3D, 2px 2px 4px #FF495F14',
			background_footerDelete: '#141722',

			interactionMenu__titleHeader: 'variable.interactionMenu__titleHeader',
			interactionMenu__headerDropdownPlaceHolder:
				'variable.interactionMenu__headerDropdownPlaceHolder',
			interactionMenu__buttonToRegisterVariable:
				'variable.interactionMenu__buttonToRegisterVariable',
			interactionMenu__buttonToCleanFilter: 'variable.interactionMenu__buttonToCleanFilter',
			dropdown__Title: 'variable.dropdown__Title',
			dropdown__TextButton: 'variable.dropdown__TextButton',
			table__Variable: 'variable.table__Variable',
			table__Value: 'variable.table__Values',
			table__Actions: 'variable.table__Actions',

			variableDelete: [],

			data: this.dropdownData,
			applyedFilters: [],

			scrollInfinite: true,
			isLoadingAction: false,
			isLoadingPage: false,
			isLoadingTable: false,
			flowData: [],
			variableTable: [],
			showModal: false,
			workflowSelected: '',
			variableValue: '',
			validation: false,
			variable: '',
			verifyVariableName: '',
			queryInput: '',
			workflowList: '',
			showSelect: false,
			selectedProject: '',
			newVariable: false,
			variableEdit: '',
			page: 1,

			dataVariable: [],

			addVariables: false,
			createVariables: false,
			editVariables: false,
			deleteVariables: false,
			viewDetailsVariables: false,
			dataTableNameVariable: '',
			dataTableValueVariable: '',
			filtering: false,
			permissionIds: PERMISSION_IDS,
			showAlert: false,
			notificationDelete: false,
			variableData: [],
		};
	},
	computed: {
		alertNotificationTitle() {
			if(this.newVariable){
				return this.$locale.baseText('variable.keepCreateVariableModal.title');

			} else if(this.notificationDelete) {
				return this.$locale.baseText('variable.keepDeleteVariableModal.title');

			} else {
				return this.$locale.baseText('variable.keepEditVariableModal.title');
			}
		},
		alertNotificationDescription() {
			if(this.notificationDelete){
				return 'Todas as parametrizações feitas na variável serão <b>excluídas</b> e você não poderá recuperá-las. O que você deseja fazer?';
			} else {
				return 'Todas as parametrizações que estão sendo feitas serão <b>perdidas</b> e você <b>não poderá recuperá-las</b>. O que você deseja fazer?';
			}
		},

		alertNotificationTitleCancel() {
			if(this.newVariable){
				return this.$locale.baseText('variable.keepCreateVariableModal.cancelButtonText');

			} else if(this.notificationDelete) {
				return this.$locale.baseText('variable.keepDeleteVariableModal.confirmButtonText');

			} else {
				return this.$locale.baseText('variable.keepEditVariableModal.cancelButtonText');
			}
		},

		alertNotificationTitleConfirm() {
			if(this.newVariable){
				return this.$locale.baseText('variable.keepCreateVariableModal.confirmButtonText');

			} else if(this.notificationDelete) {
				return this.$locale.baseText('variable.keepDeleteVariableModal.cancelButtonText');

			} else {
				return this.$locale.baseText('variable.keepEditVariableModal.confirmButtonText');
			}
		},
		workspace() {
			return this.$store.getters.workspace;
		},

	},
	async created() {
		this.isLoadingPage = true;
		await this.getAccount();
		this.isLoadingPage = false;
	},
	methods: {
		...mapActions('variable', ['getVariableId', 'deleteVariableId']),
		...mapActions('workflows', ['getWorkflows']),
		...mapActions('aliareAccount', ['getUserAccountId', 'getPermissionFromUser']),

		openModalAlert() {
			this.showAlert = true;
		},

		async openModalNewVariable(workflow) {
			await this.getWorkflowData(workflow);
			this.showModal = true;
			this.newVariable = true;
		},
		async openModalEditVariable(workflow, variableData) {
			try {
				await this.getInitWorkflow();
				await this.getWorkflowData(workflow);
				this.variable = variableData.nome;
				this.variableValue = variableData.valor;
				this.variableEdit = variableData;
				this.showModal = true;
				this.verifyVariable();
			} catch (e) {
				console.log(e);
			}
		},

		async resetModal() {
			this.showAlert = false;
			this.workflowSelected = '';
			this.selectValue = '';
			this.selectedProject = '',
			this.variableValue = '',
			this.validation = false,
			this.variable = '',
			this.variableEdit = '',
			this.verifyVariableName = '',
			this.queryInput = '',
			this.showSelect = false,
			this.newVariable = false,
			this.showModal = false;
		},

		async filterByNameVariable(dataFilter) {
			this.isLoadingTable = true;
			const statusFilter = await this.filterTable({currentFilter: 'filterByNameVariable', filterPrevNameVariable: dataFilter});
			this.isLoadingTable = false;
			if (!dataFilter.length) {
				removeItemFromArrayByValue(this.applyedFilters, 'filterByNameVariable');
			} else if (statusFilter.success) {
				this.dataTableNameVariable = dataFilter;
			}
		},

		async filterByValueVariable(dataFilter) {
			this.isLoadingTable = true;
			const statusFilter = await this.filterTable({currentFilter: 'filterByValueVariable', filterPrevValueVariable: dataFilter});
			this.isLoadingTable = false;
			if (!dataFilter.length) {
				removeItemFromArrayByValue(this.applyedFilters, 'filterByVALUEVariable');
			} else if (statusFilter.success) {
				this.dataTableValueVariable = dataFilter;
			}
		},

		async getWorkflowData(workflow) {
			try {
				this.workflowSelected = await this.restApi().getWorkflow(this.workspace.id, workflow.id);
				this.getVariable(workflow);
				this.selectValue = this.workflowSelected.tenant.id;
			} catch (e) {
				console.log(e);
			}
		},

		async filterTable(data) {
			const dataFilter = {
				currentFilter: data.currentFilter,
				valoresVariaveis: data.filterPrevValueVariable || this.dataTableValueVariable,
				nomeVariaveis: data.filterPrevNameVariable || this.dataTableNameVariable,
				applyedFilters: this.applyedFilters,

				getData: async(data) => {
					return this.restApi().getVariableDataFromWorkflow(this.workspace.id, this.showDropdown.id, data);
				},
			};

			const resultDataFilter = await filter(dataFilter);

			this.applyFilter(dataFilter.currentFilter, resultDataFilter.dataFiltered);
			return {
				success: true,
				dataFilter,
				dataFiltered: resultDataFilter.dataFiltered,
			};
		},

		applyFilter(currentFilter, dataFiltered) {
			this.filtering = true;
			this.variableTable = dataFiltered;

			if (!this.applyedFilters.includes(currentFilter)) {
				this.applyedFilters.push(currentFilter);
			};
		},

		clearFilter() {
			this.filtering = false;
			this.applyedFilters = [];
			this.variableTable = this.variableTableTemp;
		},

		pagination() {
			if (this.flowData?.length % 10 === 0) {
				this.page++;
				const payload = { workspaceId: this.workspace.id, size: 10, page: this.page };

				this.getWorkflows(payload)
					.then((response) => {
						for (const index in response.data) {
							this.flowData.push(response.data[index]);
						}
					})
					.catch((error) => {
						this.$store.commit('activeAlert', {
							message: error.response?.data?.error_description || error.message || error.toString(),
							status: 'error',
						});
					});
			} else {
				this.scrollInfinite = false;
			}
		},
		async variablePost() {
			if (this.selectValue) {
				setupHeaderAliareTenant(this.selectValue);

				try {
					await this.restApi().createNewVariable(this.workspace.id, {
						workflowId: this.workflowSelected.id,
						nome: this.variable,
						valor: this.variableValue,
					});

					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('variable.successMessageCreateVariable', { interpolate: { savedVariableName: this.variable, savedWorkflowName: this.workflowSelected.nome, savedProjectName: this.workflowSelected.projeto.nome }}),
						status: 'success',
					});

					// Verify if workflow open is the same of added variable
					if (this.showDropdown.nome === this.workflowSelected.nome ) {
						this.getTable({ id: this.workflowSelected.id});
					}

					this.resetModal();
				} catch(e) {
					this.$store.commit('activeAlert', {
						message: `Erro ao criar variável. Tente Novamente.`,
						status: 'error',
					});
				};
			}
		},
		disableInput() {
			this.showSelect = false;
		},
		activeSearch() {
			if (this.newVariable) return;

			this.showSelect = !this.showSelect;
			this.$nextTick(() => {
				this.$refs.aliareInput.$refs.input.focus();
			});
		},
		getVariable(data) {
			this.verifyVariableName = [];
			this.restApi().getVariableDataFromWorkflow(this.workspace.id, data.id)
				.then((response) => {
					this.verifyVariableName = response;
				})
				.catch((error) => {
					console.log('error', error);
				});
		},
		async searchInfoByName() {
			if (this.queryInput.length > 2) {
				try {
					this.workflowList = await this.restApi().getWorkflows(this.workspace.id, {
						nome: this.queryInput,
						size: 5,
					});
				} catch (e) {
					console.log(e);
				}
			} else {
				// Limpar o data quando menor que 2 caracteres no input
				this.workflowList = [];
				this.getInitWorkflow();
			}
		},
		async getInitWorkflow() {
			if(this.queryInput.length === 0) {
				this.showSelect = false;
				try {
						this.workflowList = await this.restApi().getWorkflows(this.workspace.id, {
							size: 5,
						});

						await this.workflowList.sort(function(x,y) {
							let a = x.nome.toUpperCase(),
									b = y.nome.toUpperCase();
							return a == b ? 0 : a> b ? 1 : -1;
						});
				} catch (e) {
						console.log(e);
				}
			}
		},
		verifyVariable() {
			if (this.verifyVariableName.length === 0 || this.variable === this.variableEdit.nome) {
				this.validation = true;
			} else {
				for (const index in this.verifyVariableName) {
					if (this.variable === this.verifyVariableName[index].nome) {
						this.validation = false;
						break;
					} else {
						this.validation = true;
					}
				}
			}
		},
		triggerDropdown(dropdown) {
			if (this.showDropdown == dropdown) {
				this.showDropdown = '';
			} else {
				this.showDropdown = dropdown;
			}
		},

		parseJwt(token) {
			return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
		},

		async getAccount() {
			setupHeaderAliareTenant(this.$store.getters.tenantId);
			this.getWorkflows({workspaceId: this.workspace.id})
				.then((resultDetail) => {
					this.flowData = resultDetail.data;
				})
				.catch((error) => {
					this.$store.commit('activeAlert', {
						message: error.response?.data?.error_description || error.message || error.toString(),
						status: 'error',
					});
				});
		},

		async getTable(data) {
			this.isLoadingTable = true;
			this.variableTable = [];

			await this.restApi().getVariableDataFromWorkflow(this.workspace.id, data.id)
				.then((response) => {
					this.variableTable = response;
					this.variableTableTemp = response;
				})
				.catch((error) => {
					this.$store.commit('activeAlert', {
						message: error,
						status: 'error',
					});
				});

			this.isLoadingTable = false;
		},

		async patchVariable() {
			let workflowOpen = this.variableEdit.workflowId;
			const payload = {
				id: this.variableEdit.id,
				workflowId: this.workflowSelected.id,
				nome: this.variable,
				valor: this.variableValue,
			};
			if (this.selectValue) {
				setupHeaderAliareTenant(this.selectValue);
				try {
					await this.restApi().updateVariable(this.workspace.id, payload);

					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('variable.successMessageEditVariable', { interpolate: { savedVariableName: this.variable, savedWorkflowName: this.workflowSelected.nome, savedProjectName: this.workflowSelected.projeto.nome }}),
						status: 'success',
					});
					this.resetModal();
					this.getTable({ id: workflowOpen });
				} catch (e) {
					this.$store.commit('activeAlert', {
						message: 'Erro ao alterar variável. Tente Novamente.',
						status: 'error',
					});
				};
			};
		},
		async deleteVariable() {
			this.isLoadingAction = true;

			await this.deleteVariableId({workspaceId: this.workspace.id, variableId: this.variableData.id})
				.then(() => {
					this.$store.commit('activeAlert', {
						message: this.$locale.baseText('variable.successDeleteVariable', { interpolate: { deleteVariableName: this.variableData.nome}}),
						status: 'success',
					});

					this.showAlert = false;
					this.notificationDelete = false;
					this.getTable({ id: this.variableData.workflowId});
				})
				.catch((error) => {
					this.$store.commit('activeAlert', {
						message: 'Erro ao excluir Variável',
						status: 'error',
					});
				});
			this.isLoadingAction = false;
		},
	},
});
</script>

<style lang="scss" scoped>
.dropdown {
	width: 100%;
	padding: 0% 1.62% 1.62% 1.62%;
	.dropdown__titleBox {
		width: 100%;
		height: 40px;
		background: #233149;
		border-radius: 4px 4px 0px 0px;
		padding: 0px 34px 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.dropdown__title {
			display: flex;
			align-items: center;
			cursor: pointer;

			.dropdown__titleArrowIcon {
				background-image: url('./../assets/cardArrow.svg');
				background-position: center;
				background-repeat: no-repeat;
				width: 28px;
				height: 28px;
				transform: rotate(90deg);
				margin: 0px 16px 0px 0px;
				transition: 0.2s transform;
			}
			.dropdown__titleActiveArrowIcon {
				width: 28px;
				height: 28px;
				background: rgba(0, 195, 221, 0.08);
				border-radius: 4px;
				background-image: url('./../assets/arrowIconBlue.svg');
				background-position: center;
				background-repeat: no-repeat;
				margin: 0px 16px 0px 0px;
				transform: rotate(0deg);
			}
		}
		.dropdown__funnelIcon {
			background-image: url('./../assets/funnelIcon.svg');
			width: 16px;
			height: 16px;
			cursor: pointer;
		}
	}

	.dropdown__bodyBox {
		max-height: 65vh;
		overflow-y: auto;
		.dropdown__dropdownRowShow {
			border-bottom: 1px solid #34476a;
			padding: 16px;

			.dropdown__dropdownRow {
				display: flex;
				justify-content: space-between;
				padding-right: 16px;
				.dropdown__dropdownRowTitle {
					display: flex;
					align-items: center;
					cursor: pointer;
					.dropdown__dropdownRowTitleArrowIcon {
						background-image: url('./../assets/cardArrow.svg');
						background-position: center;
						background-repeat: no-repeat;
						width: 28px;
						height: 28px;
						transform: rotate(90deg);
						margin: 0px 16px 0px 15px;
						transition: 0.2s transform;
					}
					.dropdown__dropdownRowTitleActiveArrowIcon {
						width: 28px;
						height: 28px;
						background: rgba(0, 195, 221, 0.08);
						border-radius: 4px;
						background-image: url('./../assets/arrowIconBlue.svg');
						background-position: center;
						background-repeat: no-repeat;
						margin: 0px 16px 0px 15px;
						transform: rotate(0deg);
					}
					.dropdown__dropdownRowTitleText {
						font-weight: 500;
						font-size: 16px;
						line-height: 19px;
					}
					.dropdown__dropdownRowSubtitleText {
						font-weight: 400;
						font-size: 14px;
						line-height: 16px;
						color: rgba(255, 255, 255, 0.64);
					}
				}
				.dropdown__dropdownRowButton {
					height: 40px;
					width: 224px;
					border-radius: 4px;
					cursor: pointer;
					display: flex;
					align-items: center;
					padding: 0px 16px;
					.dropdown__dropdownRowButtonIcon {
						background-image: url('./../assets/cardPlus.svg');
						width: 14px;
						height: 14px;
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						margin-right: 8px;
					}
					.dropdown__dropdownRowButtonText {
						font-weight: 500;
						font-size: 14px;
						line-height: 16px;
						text-transform: capitalize;
						color: #00c3dd;
					}
				}
				.dropdown__dropdownRowButton:hover {
					background: rgba(0, 195, 221, 0.08);
					border: 1px solid #00c3dd;
				}
				.dropdownDisable {
					border: 1px solid #6c757d;

					.dropdownIconDisable {
						background-image: url('./../assets/cardPlusGrey.svg');
					}
					.dropdownTextDisable {
						color: #6c757d;
					}

					&:hover {
						display:flex;
						position: sticky;
						z-index: 9;
						border: 1px solid #6c757d;
						background: #6c757d26;

						.tooltipAliare {
							display: flex;
						}
					}
				}
			}
			.dropdown__dropdownRowShowTable {
				margin-left: 44px;
				margin-top: 10px;
			}
		}
	}
}


.createVariable__bodySecondSectionVariable {
	display: flex;
	flex-direction: column;
	row-gap: 24px;
}
.createVariable__bodySecondSectionSelectDiv, .createVariable__bodySecondSectionVariable {
	width: 100%;

	.createVariable__bodySecondSectionVariableVariableSuccess {
		color: #59d64c;
		padding-left: 8px;
		font-size: 12px;
	}
	.createVariable__bodySecondSectionVariableVariableError {
		color: #ff495f;
		padding-left: 8px;
		font-size: 12px;
	}
}
.createVariable__bodySecondSectionSelectOptionsBackground {
	margin-top: 8px;
	max-height: 25vh;
	overflow-y: scroll;
	z-index: 9;
	position: absolute;
	background-color: #223049;
	border: 1px solid #4a638f;
	border-radius: 4px;
	width: calc(100% - 30px);
	color: rgba(255, 255, 255, 0.88);
	padding: 16px;
	.createVariable__bodySecondSectionSelectOptions {
		width: 100%;
		cursor: pointer;

		.createVariable__bodySecondSectionSelectOptionsFlow {
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: rgba(255, 255, 255, 0.88);
		}
	}
}
.createVariable__bodySecondSectionSelect {
	background-color: #223049;
	border: 1px solid #4a638f;
	border-radius: 4px;
	line-height: 1;
	width: 100%;
	display: flex;
	height: 58px;
	justify-content: center;
	flex-direction: column;
	row-gap: 4px;
	color: rgba(255, 255, 255, 0.88);
	padding: 11px 9px;

	&.blockedSelect {
		cursor: default;
	}
}
.dropdown__dropdownRowButton {
	width: 234px !important;
}
.input__modalSelect {
	width: 486px;
	min-height: 58px;
	background-color: #223049;
	border: 1px solid #4a638f;
	border-radius: 4px;
	padding: 12px 9px;
	margin: 12px 0px 0px 0px;
}

.modal__backgrounOptions {
	background-color: #223049;
	border: 1px solid #4a638f !important;
	width: 486px !important;
	z-index: 9000;
	position: absolute;
	max-height: 100px !important;
	overflow-y: scroll !important;
}

.modal__options {
	padding: 9px 9px !important;
	display: flex !important;
	flex-direction: column !important;
}

.modal__inputOptionTitle {
	font-weight: 400 !important;
	font-size: 14px !important;
	line-height: 16px !important;
	color: rgba(255, 255, 255, 0.88) !important;
}

.modal__inputOptionSubtitle {
	font-weight: 400 !important;
	font-size: 12px !important;
	line-height: 14px !important;
	color: rgba(255, 255, 255, 0.56) !important;
}

.input__Parameter {
	margin: 24px 0px 0px 0px !important;
}

.select {
	font-family: 'Roboto', sans-serif !important;
	background-color: #223049 !important;
	border: 1px solid #4a638f;
	border-radius: 4px !important;
	height: 40px;
	width: 254px;
	color: #ffffff;
	padding: 10px 9px;
	margin-inline-end: 32px;
}



.first__input {
	margin: 0px 0px 12px 0px !important;
}

.modal__inputOption {
	display: flex;
	flex-direction: column;
}

.modal__warningParagraph {
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #59d64c;
	width: 468px;
	height: 14px;
	margin-bottom: 10px;
}

.container-select {
	display: flex;
	align-items: center;
	justify-content: space-between;
	justify-items: start;
}

.img-execute {
	padding-right: 8px;
}

.span-button {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.el-button {
	background-color: transparent;
	color: #00c3dd;
	border-radius: 4px;
	border: 1px solid #00c3dd;
	padding-right: 16px;
	padding-left: 16px;
}

.button {
	display: flex;
	align-content: center;
}

:deep {
	.modal-mask {
		z-index: 999;
	}
	.modal-body {
		row-gap: 24px;
		padding: 17px;
		align-items: start;
	}

	.input-custom--createVariable {
		input {
			height: 58px;
		}
	}

	.modal-container {
		border-radius: 4px;
		box-shadow: -2px -2px 4px rgba(255, 255, 255, 0.08), 2px 2px 4px rgba(0, 0, 0, 0.24);
		border: 1px solid #34476a;
		display: flex;
		flex-direction: column;
	}
}

.modal-header {
	border-bottom: 1px solid #34476a !important;
}

.modal__headerIcon {
	width: 14px;
	height: 14px;
	background-image: url('./../assets/closeIcon.svg');
	cursor: pointer;
}

.modal__saveIcon {
	background-image: url('./../assets/floppyDiskIcon.svg');
	width: 18px;
	height: 18px;
	background-position: center;
	background-repeat: no-repeat;
}

.modal-enter-from {
	opacity: 0;
}

.modal-leave-to {
	opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.modal__headerDelete {
	display: flex;

	.modal__deleteIcon {
		background-image: url('./../assets/cardIconDelete.svg');
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		width: 22px;
		height: 19px;
		margin-right: 16px;
	}

	.modal__deleteTitleText {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: #ff495f;
	}
}

.modal__deleteBody {
	padding: 0px 18px 0px 56px;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
}

.modal__continue {
	display: flex;
	flex-direction: column;
	width: 220px;
	height: 40px;
	background-color: #141722;
	border: 1px solid #00c3dd;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.32);
	border-radius: 4px;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #00c3dd;
}

.modal__continue:hover {
	background-color: #00c3dd;
	color: #000000;
}

.modal__cancel {
	width: 220px;
	height: 40px;
	background-color: #141722;
	border: 1px solid #ff495f;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.32);
	border-radius: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #ff495f;
}

.modal__cancel:hover {
	background-color: #ff495f;
	color: #000000;
}


.columnActionEditDisable {
	width: 32px;
	height: 32px;
	background: #1c2638;
	border: 1px solid #4a638f;
	border-radius: 4px 0px 0px 4px;
	cursor: pointer;
	background-image: url('../assets/pen.svg');
	background-position: center;
	background-repeat: no-repeat;
	box-shadow: 2px 2px 5px black;
}
.columnActionEditDisable:hover {
	background-color: #29364f;

	.tooltipAliare {
		display: flex;
	}
}
.columnActionDeleteDisable {
	width: 32px;
	height: 32px;
	background: #1c2638;
	border: 1px solid #4a638f;
	border-radius: 0px 4px 4px 0px;
	cursor: pointer;
	background-image: url('../assets/deleteGrey.svg');
	background-position: center;
	background-repeat: no-repeat;
	box-shadow: 2px 2px 5px black;
}
.columnActionDeleteDisable:hover {
	background-color: #29364f;

	.tooltipAliare {
		display: flex;
	}
}

</style>
