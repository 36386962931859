/** Arquivo de configuração para incorporar variáveis de ambiente **/

const deployConfig = window.configuration;

const config = {
	host: parse(process.env.VUE_APP_HOST, deployConfig.host),
	baseURL: parse(process.env.VUE_APP_API_BASE_URL, deployConfig.baseURL),
	backendURL: parse(process.env.VUE_APP_API_BASE_URL, deployConfig.baseURL) + "/api/v1",
	n8n: {
		baseURL: parse(process.env.VUE_APP_N8N_BASE_URL, deployConfig.n8n.baseURL),
	},
	plataformaAliare: {
		baseURL: parse(process.env.VUE_APP_PLATAFORMA_ALIARE_BASE_URL, deployConfig.plataformaAliare.baseURL),
		apiURL: parse(undefined, deployConfig.plataformaAliare.apiURL),
		authorityURL: parse(undefined, deployConfig.plataformaAliare.authorityURL),
	},
	auth: {
		client_id: parse(process.env.VUE_APP_AUTH_CLIENT_ID, deployConfig.auth.client_id),
		client_secret: parse(process.env.VUE_APP_AUTH_CLIENT_SECRET, deployConfig.auth.client_secret),
		code_challenge_method: parse(process.env.VUE_APP_AUTH_CODE_CHALLENGE_METHOD, 'S256'),
		grant_type: parse(process.env.VUE_APP_AUTH_GRANT_TYPE, deployConfig.auth.grant_type),
		redirect_uri: parse(process.env.VUE_APP_AUTH_REDIRECT_URI, 'auth'),
		response_type: parse(process.env.VUE_APP_AUTH_RESPONSE_TYPE, 'code'),
		scope: parse(process.env.VUE_APP_AUTH_SCOPE, deployConfig.auth.scope),
		setCookies: Boolean((process.env.VUE_APP_AUTH_GRANT_TYPE ?? deployConfig.auth.scope) === "authorization_code"),
	},
	vistra: {
		painelFalhasIdentificadas: parse(undefined, deployConfig.vistra.painelFalhasIdentificadas),
		painelResultadoIntegracoes: parse(undefined, deployConfig.vistra.painelResultadoIntegracoes),
	},
};

function parse(value, fallback) {
	if (typeof value === 'undefined') {
		return fallback;
	}
	switch (typeof fallback) {
		case 'boolean':
			return !!JSON.parse(value);
		case 'number':
			return JSON.parse(value);
		default:
			return value;
	}
}

function findConfig(configObject, splittedConfigName) {
	const splittedConfig = splittedConfigName.slice();
	const configKey = splittedConfig.shift();

	if (splittedConfig.length === 0) {
		return configObject[configKey];
	}

	if (configObject[configKey]) {
		return findConfig(configObject[configKey], splittedConfig);
	}

	return undefined;
}

export function appConfig(configName) {
	if (typeof configName !== "string") return undefined;

	return findConfig({ ...config }, configName.split('.'));
}

export function feature(featureName) {
	if (typeof featureName !== "string") return undefined;

	return findConfig({ ...config.features }, featureName.split('.'));
}

export default appConfig;
