
import mixins from 'vue-typed-mixins';
import WebStorage from '@/common/WebStorage';
import { mapActions, mapGetters } from 'vuex';
import { restApi } from '@/components/mixins/restApi';
import { workspacePermission } from '@/components/mixins/permissions/workspace';
import BackgroundView from '@/components/BackgroundView.vue';
import { mainSiderBarPermission } from '@/components/mixins/permissions/sidebar';
import { accessIntegrationPanelPermission } from '@/components/mixins/permissions/panelInfo';
import moment from 'moment';

import SelectTree from '@/components/SelectTree.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import MenuOptions from '@/components/MenuOptions.vue';
import ScrollInfinite from '@/components/ScrollInfinite.vue';
import ModalButtonCancel from '@/components/ModalButtonCancel.vue';
import Table from '@/components/Table.vue';
import ModalButtonSuccess from '@/components/ModalButtonSuccess.vue';
import AlertNotificationModal from '@/components/AlertNotificationModal.vue';

export default mixins(
	restApi,
	workspacePermission,
	mainSiderBarPermission,
	accessIntegrationPanelPermission,
).extend({
	components: {
		MenuOptions,
		ScrollInfinite,
		ModalButtonCancel,
        Table,
        BackgroundView,
        ProgressBar,
		ModalButtonSuccess,
		AlertNotificationModal,
        SelectTree,
	},
	data() {
		return {
			page: 1,
			size: 10,
			isLoading: false,
            mockData: {
                listServer: [
                    {
                        id: '212312',
                        nome: 'aks-nodepool1-10543833-7',
                        usoCpu: '99%',
                        quantidadeDeNucleo: '2',
                        usoMemoria: '70%',
                        quantidadeDeMemoria: '32GB',
                        instancias: [
                            {
                                nome: 'aliare-integra-n8n-worker-7b8fc48d88-2dk',
                                categoria: 'Executor',
                                usoCpu: '70%',
                                usoMemoria: '50%',
                                id: '1234'
                            },
                            {
                                nome: 'aliare-integra-n8n-worker-7b8fc48d88-2dk',
                                categoria: 'Executor',
                                usoCpu: '20%',
                                usoMemoria: '50%',
                                id: '123311314'
                            }
                        ],
                
                    },
                    {
                        id: '21sasa2312',
                        nome: 'aks-nodepool1-10543833-7',
                        usoCpu: '20%',
                        quantidadeDeNucleo: '2',
                        usoMemoria: '85%',
                        quantidadeDeMemoria: '32GB',
                        instancias: [
                            {
                                nome: 'aliare-integra-n8n-worker-7b8fc48d88-2dk',
                                categoria: 'API',
                                usoCpu: '70%',
                                usoMemoria: '50%',
                                id: '1234'
                            },
                            {
                                nome: 'aliare-integra-n8n-worker-7b8fc48d88-2dk',
                                categoria: 'Executor',
                                usoCpu: '20%',
                                usoMemoria: '50%',
                                id: '123311314'
                            }
                        ],
                
                    },
                    {
                        id: '124124',
                        nome: 'aks-nodepool1-10543833-7',
                        usoCpu: '99%',
                        quantidadeDeNucleo: '2',
                        usoMemoria: '70%',
                        quantidadeDeMemoria: '32GB',
                        instancias: [
                            {
                                nome: 'aliare-integra-n8n-worker-7b8fc48d88-2dk',
                                categoria: 'Executor',
                                usoCpu: '70%',
                                usoMemoria: '50%',
                                id: '1sdsas234'
                            }
                        ],
                
                    },
                
                ],
            },
			scrollInfinite: false,
			isLoadingPage: false,
            alertData: {
                show: false,
            },
            isLoadingAction: false,
            filterByEnvironment: {
                id: '1',
                label: 'Ambiente Compartilhado'
            },
            timeToRefresh: {
                id: '1',
                label: '10s'
            },
            optionsListEnviroment: [
				{
					id: 1,
					label: 'Ambiente Compartilhado',
				},
				{
					id: 2,
					label: 'Bom Jesus',
				},
				{
					id: 3,
					label: 'Coplacana',
				},
                {
					id: 4,
					label: 'Operação',
				},
                {
					id: 5,
					label: 'Rancho Alegre',
				},
                {
					id: 6,
					label: 'Terra Fertil',
				},
			],
            optionsListTime: [
                {
					id: 1,
					label: '10s',
				},
				{
					id: 2,
					label: '30s',
				},
				{
					id: 3,
					label: '60s',
				},
            ]
		};
	},
	computed: {
		...mapGetters('ui', {
			isCollapsed: 'sidebarMenuCollapsed',
		}),
		organizationId() {
			return WebStorage.getItem('tenantId');
		},

	},
	async created() {
		this.isLoadingPage = true;
        setTimeout(() => {
               		this.isLoadingPage = false;

            }, 3000);
		// await this.verifyPermissionUserAndInitiate();
	},
	mounted() {
	},
	beforeDestroy() {
	},
	methods: {
        toggleDropdown(index) {
            this.$set(this.mockData.listServer[index], 'showDropdown', !this.mockData.listServer[index].showDropdown);
        },
        onSelectEnviroment(type) {
			this.filterByEnvironment = type;
		},
        onSelectTime(type) {
			this.timeToRefresh = type;
		},
        restartInstance() {
            this.isLoadingAction = true;

            setTimeout(() => {
                this.isLoadingAction = false;
                this.alertData = {
                    show: false,
                }

                this.$store.commit('activeAlert', {
					message: 'Iniciamos a reinicialização da Instância.',
				});
            }, 3000);
        },
        openModalAlertRestart(instance) {
            this.alertData = {
                show: true,
                instance,
            }
        },

	},
});
