<template>
  <div class="progress-bar-container">
    <div class="progress-bar" :style="{ width: progress + '%', backgroundColor: progressColor }"></div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: String,
      required: true
    }
  },
  computed: {
    progressColor() {
      if (this.progress <= 70) {
        return '#59D64C';
      } else if (this.progress <= 90) {
        return '#FFA53B';
      } else {
        return '#FF495F';
      }
    }
  }
}
</script>

<style scoped>
.progress-bar-container {
  width: 100%;
  height: 8px;
  background-color: #1C2638;
  border-radius: 1px;
  overflow: hidden;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.2);
}

.progress-bar {
  height: 100%;
  transition: width 0.2s ease-in-out;
}
</style>
